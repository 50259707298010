import axios from 'axios'

const cleanCacheVersion = async () => {
  try {
    const { data: { version } } = await axios.get('https://hub.onlypay.com.br:3030/version/front')
    if (localStorage.getItem('version') !== version.toString()) {
      localStorage.setItem('version', version.toString())
      window.location.reload(true)
    }
  } catch (err) {
    console.log('# cleanCacheVersion =>', err)
  }
}

export default cleanCacheVersion
