const locale = {
  locale: 'ptBR',
  dictionary: {
    ptBR: {
      messages: {
        card_format: field => `O campo ${field} possui um formato inválido.`,
        cep: field => `O campo ${field} possui um formato inválido.`,
        cnpj: field => `O ${field} está inválido.`,
        cnpj_format: field => `O campo ${field} possui um formato inválido.`,
        cpf: field => `O ${field} está inválido.`,
        cpf_format: field => `O campo ${field} possui um formato inválido.`,
        // confirmed: (field, [confirmedField]) => `
        //   Os campos ${field} e ${document.querySelector(`[name="${confirmedField}"]`).dataset.vvAs} devem ser iguais.
        // `,
        confirmed: (field) => `
          Os campos de ${field} devem ser iguais.
        `,
        date_format: field => `O campo ${field} deve estar no formato dd/mm/aaaa.`,
        email: field => `O campo ${field} possui um formato inválido.`,
        max: (field, [length]) => `O campo ${field} não deve ter mais que ${length} caracteres.`,
        min: (field, [length]) => `O campo ${field} deve conter pelo menos ${length} caracteres.`,
        money: field => `O ${field} precisa ser R$ 10,00 ou mais.`,
        money_TED: field => `O ${field} precisa ser entre R$ 10,00 e R$ 10.000,00.`,
        password_one_capital_letter: field => `O campo ${field} deve conter pelo menos uma letra maiúscula.`,
        password_one_lower_case_letter: field => `O campo ${field} deve conter pelo menos uma letra minúscula.`,
        password_one_letter: field => `O campo ${field} deve conter pelo menos uma letra.`,
        password_one_number: field => `O campo ${field} deve conter pelo menos um número.`,
        password_special_characters: field => `O campo ${field} deve conter pelo menos dois caracteres especial.`,
        phone: field => `O campo ${field} possui um formato inválido.`,
        required: field => `O campo ${field} é obrigatório.`,
      },
    },
  },
};

const validators = {
  card_format(value) {
    return /^\d{4} \d{4} \d{4} \d{4}$/.test(value);
  },
  cep(value) {
    return /^[0-9]{5}-[0-9]{3}$/.test(value);
  },
  cnpj(value) {
    let cnpj = value;
    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    cnpj = cnpj.replace(/[^\d]/g, '');

    if (cnpj.length !== 14) return false;
    if (/0{14}/.test(cnpj)) return false;

    // eslint-disable-next-line
    for (var i = 0, n = 0; i < 12; n += cnpj[i] * b[++i]);

    // eslint-disable-next-line
    if (cnpj[12] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;

    // eslint-disable-next-line
    for (var i = 0, n = 0; i <= 12; n += cnpj[i] * b[i++]);

    // eslint-disable-next-line
    if (cnpj[13] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;

    return true;
  },
  cnpj_format(value) {
    return /^\d{2}\.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
  },
  confirmed(value, field) {
    return value === document.querySelector(`[name="${field}"]`).value;
  },
  cpf(value) {
    const cpf = value.replace(/\D/g, '');
    if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
    let result = true;
    [9, 10].forEach((j) => {
      let sum = 0;
      let r;
      cpf.split(/(?=)/).splice(0, j).forEach((e, i) => {
        sum += parseInt(e, 10) * ((j + 2) - (i + 1));
      });
      r = sum % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r !== parseInt(cpf.substring(j, j + 1), 10)) {
        result = false;
      }
    });
    return result;
  },
  cpf_format(value) {
    return /^\d{3}\.\d{3}.\d{3}-\d{2}$/.test(value);
  },
  date_format(value) {
    return /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/.test(value);
  },
  money(value) {
    return value >= 10;
  },
  moneyTED(value) {
    return (value < 10 && value <= 10000);
  },
  password_one_capital_letter(value) {
    return /[A-Z]+/.test(value);
  },
  password_one_lower_case_letter(value) {
    return /[a-z]+/.test(value);
  },
  password_one_letter(value) {
    return /[\w]+/.test(value);
  },
  password_one_number(value) {
    return /\d+/.test(value);
  },
  password_special_characters(value) {
    // min 2 special characters
    return /(.*?[\W_].*?){2,}/.test(value);
    // return /[\`\~\!\@\#\$\%\^\&\*\(\)\-\_\+\=\[\]\{\}\\\|\;\:\'\"\,\.\<\>\/\?]{2,}/.test(value);
  },
  phone(value) {
    const phone = value.replace(/\D/g, '');
    if (/^(\+?55)?0\d{2}(9?\d{4}\d{4}|9?\d{1}\d{4}\d{4})$/.test(phone)) {
      return !(/(\d)\1{6,}/.test(phone));
    }
    return false;
  },
  // phone(value) {
  //   const phone = value.replace(/\D/g, '');
  //   if (/^(\+?55\s?)?0([1-9]{2}|[2-9]{1}[1-9]{1}|\([1-9]{2}\)|\([2-9]{1}[1-9]{1}\))\s?(9)\s?(\d{4})-?(\d{4})$/g.test(phone)) {
  //     return !(/0000000|1111111|2222222|3333333|4444444|5555555|6666666|7777777|8888888|9999999/.test(phone));
  //   } return false;
  // },
};

export { locale, validators };
