import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import currentDate from './helpers/current-date';
import store from './store';

const http = axios.create({
  baseURL: process.env.VUE_APP_HUB_BASE_URL
});

http.interceptors.request.use(
  config => {
    // store.commit('formLoading', true)
    config.headers['op-version'] = `C-${currentDate}-WEB`;
    if (window.$cookies.isKey('token')) {
      config.headers.Authorization = `Bearer ${window.$cookies.get('token')}`;
    }
    return config;
  },
  err => {
    store.commit('formLoading', false);
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  res => {
    const { data } = res;
    store.commit('formLoading', false);
    if (!data) return false;
    if (data.code >= 400 && data.code <= 500) {
      store.commit('message', { type: 'error', text: data.message });
    }
    return res;
  },
  err => {
    store.commit('formLoading', false);
    if (!err.response) {
      store.commit('message', {
        type: 'error',
        text:
          'Não foi possível processar a sua requisição no momento, tente novamente mais tarde.'
      });
      return Promise.reject(new Error('Verifique sua conexão.'));
    }
    if (err.response.status === 307) {
      store.commit('message', {
        type: 'error',
        text: 'Sua conta requer uma atualização de senha.'
      });
      store.commit('changePassword');
      router.push({ name: 'changePassword' });
      return Promise.reject(
        new Error('Sua conta requer uma atualização de senha.')
      );
    }
    if (err.response.status === 401 && err.response.data.error !== 'Código inválido ou já utilizado') {
      // remove cache and cookies after signout
      localStorage.clear();
      // window.$cookies.removeAll()
      store.commit('signOut');
      store.commit('message', {
        type: 'error',
        text: 'CPF/CNPJ ou Senha Inválidos.'
      });
      router.push({ name: 'signIn' });
      return Promise.reject(new Error('CPF/CNPJ ou Senha Inválidos'));
    }

    if (err.response.status === 401 && err.response.data.error === 'Código inválido ou já utilizado') {
      // remove cache and cookies after signout
      localStorage.clear();
      // window.$cookies.removeAll()
      store.commit('signOut');
      store.commit('message', {
        type: 'error',
        text: err.response.data.error
      });
      router.push({ name: 'signIn' });
      return Promise.reject(new Error(err.response.data.error));
    }

    // check if route request is users/find-last-login
    if (err.response.config.url.indexOf('users/find-last-login') >= 0) {
      return Promise.reject(new Error(null));
    }
    if (
      typeof err.response.data.error === 'string'
      && err.response.data.error.toLowerCase().indexOf('token') >= 0
    ) {
      // remove cache and cookies after signout
      localStorage.clear();
      // window.$cookies.removeAll()
      store.commit('signOut');
      store.commit('message', {
        type: 'error',
        text: 'Sessão expirada, entre novamente.'
      });
      return Promise.reject(new Error('Sessão expirada'));
    }
    const error = err.response.data && err.response.data.error;
    let message = err.response.data || error || 'Tente novamente';
    if (typeof error === 'string') {
      message = error;
    }
    if (message && message.details && message.details[0]) {
      const msgs = [];
      message.details.map(msg => {
        return msgs.push(msg.message);
      });
      store.commit('message', { type: 'error', text: msgs.join(', ') });
    }
    if (typeof error === 'string') {
      store.commit('message', { type: 'error', text: error });
    }
    if (typeof message === 'string') {
      store.commit('message', { type: 'error', text: message });
    }
    return Promise.reject(message);
  }
);

export default http;
