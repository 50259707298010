import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import router from './router';

Vue.use(Vuex);

const initialState = () => ({
  blockCard: false,
  cards: [],
  currentCard: {},
  formLoading: false,
  userPassword: '',
  message: {},
  pageLoading: false,
  user: {},
  documentType: '',
  codeSended: '',
  availableCards: false
});

export default new Vuex.Store({
  state: initialState,
  mutations: {
    clear(state) {
      const s = initialState();
      Object.keys(s).forEach((k) => { Vue.set(state, k, s[k]); });
    },
    formLoading(state, value) {
      Vue.set(state, 'formLoading', value);
    },
    message(state, { type, text }) {
      Vue.set(state.message, 'text', false);
      clearTimeout(state.message.timeout);
      state.message = {
        type,
        text,
        timeout: setTimeout(() => { state.message.text = false; }, 6000),
      };
    },
    pageLoading(state, value) {
      Vue.set(state, 'pageLoading', value);
    },
    setAvailableCards(state, value) {
      Vue.set(state, 'availableCards', value);
    },
    setAvatar(state, url) {
      Vue.set(state.user, 'avatar', url);
    },
    setCards(state, cards) {
      Vue.set(state, 'cards', cards);
    },
    setCurrentCard(state, card) {
      Vue.set(state, 'currentCard', card);
    },
    setUser(state, user) {
      Vue.set(state, 'user', user);
    },
    signOut() {
      window.$cookies.remove('token');
      this.commit('clear');
      document.body.classList.remove('no-scroll');
      // router.push({ name: 'signIn' });
      // reload page and navigate to signIn
      window.location.href = router.resolve({ name: 'signIn' }).href;
    },
    changePassword() {
      window.$cookies.remove('token');
      this.commit('clear');
      document.body.classList.remove('no-scroll');
      // router.push({ name: 'signIn' });
      // reload page and navigate to signIn
      window.location.href = router.resolve({ name: 'changePassword' }).href;
    },
    toggleBlockCard(state, value) {
      Vue.set(state, 'blockCard', value !== undefined ? value : !state.blockCard);
      if (state.blockCard) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    toggleRequestNewCard(state, value) {
      Vue.set(state, 'requestNewCard', value !== undefined ? value : !state.requestNewCard);
      if (state.requestNewCard) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    updateCard(state, card) {
      state.cards.splice(state.cards.findIndex(c => c.id === card.id), 1, card);
    },
    setCodeSended(state, codeSended) {
      Vue.set(state, 'codeSended', codeSended)
    },
    setDocumentType(state, documentType) {
      Vue.set(state, 'documentType', documentType)
    },
    setUserPassword(state, userPassword) {
      Vue.set(state, 'userPassword', userPassword)
    }
  },
  actions: {
    setCardUnlockCode({ commit }, codeSended) {
      commit('setCodeSended', codeSended);
    },
    setDocumentType({ commit }, documentType) {
      commit('setDocumentType', documentType);
    }
  },
  getters: {
    getCardUnlockCode(state) {
      return state.codeSended;
    },
    getDocumentType(state) {
      return state.documentType;
    }
  }
});
