const formatCurrentDate = date => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
};

const date = new Date();
const currentDate = formatCurrentDate(date);

export default currentDate;
