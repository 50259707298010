import Vue from 'vue';
import Moment from 'moment';
import Money from 'v-money';
import VueCookies from 'vue-cookies';
import VeeValidate, { Validator } from 'vee-validate';
import VueAnalytics from 'vue-analytics';
import vueDebounce from 'vue-debounce';
import App from './App.vue';
import http from './http';
// import './registerServiceWorker'
import router from './router';
import store from './store';
import { locale, validators } from './validations';

Vue.prototype.$http = http;
Vue.prototype.$moment = Moment;

Object.entries(validators).forEach(([key, value]) => Validator.extend(key, value));
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA,
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href
      };
    }
  }
});
Vue.use(vueDebounce, {
  listenTo: 'oninput'
});
Vue.use(Money, {
  decimal: ',',
  masked: false,
  precision: 2,
  prefix: 'R$ ',
  thousands: '.'
});
Vue.use(VueCookies);
Vue.use(VeeValidate, locale);

Vue.config.productionTip = false;

new Vue({ router, store, render: h => h(App) }).$mount('#app');
