var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("transition", { attrs: { name: "message" } }, [
        _vm.$store.state.message.text
          ? _c("div", {
              class: `message message--${_vm.$store.state.message.type}`,
              domProps: { innerHTML: _vm._s(_vm.$store.state.message.text) },
            })
          : _vm._e(),
      ]),
      _vm.$store.state.pageLoading
        ? _c("div", { staticClass: "page-loading" }, [
            _c("div", { staticClass: "page-loading__content" }, [
              _vm._m(0),
              _c(
                "svg",
                {
                  staticClass: "page-loading__content__img",
                  attrs: { viewBox: "0 0 634.905 340.881" },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#006EB7",
                      d: "M169.954,0c97.883,0,170.926,73.046,170.926,170.928c0,96.907-73.044,169.952-170.926,169.952\n          C73.048,340.881,0,267.835,0,170.928C0,73.046,73.048,0,169.954,0z M170.442,263.939c53.565,0,\n          90.089-40.418,90.089-93.011 c0-53.569-36.524-93.499-90.089-93.499c-52.107,0-90.091,39.93-90.091,\n          93.499C80.351,223.521,118.335,263.939,170.442,263.939z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#006EB7",
                      d: "M512.676,4.87H429.89v71.098h69.638c28.73,0,50.645,18.993,50.645,51.131 c0,30.195-21.915,\n          50.16-50.645,50.16H429.89v71.098h82.785c65.74,0,122.229-45.29,122.229-120.769 C634.905,43.339,\n          578.416,4.87,512.676,4.87z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#00FF00",
                      d: "M369.961,5.114h59.93v331.141h-81.833L348.043,98.14l-39.877,21.087l-30.683-61.855L369.961,5.114z",
                    },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-loading__content__ring" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }