<script>
// import Swal from 'sweetalert2'
import cleanCacheVersion from './helpers/clean-cache-version';

export default {
  beforeMount() {
    cleanCacheVersion();
    /*
    Swal.fire({
      hideOnOverlayClick: false,
      hideOnContentClick: false,
      closeClick: false,
      helpers: {
        overlay: { closeClick: false }
      },
      closeOnClickOutside: false,
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'OnlyPay Informa',
      html: `<p style="text-align: left;">Em virtude do feriado de Corpus Christi, não haverá expediente no dia 16.06, retornaremos as atividades em 17.06 às 9hrs.
      <br>Desta forma, os serviços de pagamentos de boletos e contas, transferências bancárias serão compensadas no próximo dia útil.
      <br>Para maiores informações acesse sua conta no site onlypay.com.br.</p>`,
      type: 'info'
    })
    */
    // Swal.fire({
    //   html: `
    //     <div style='text-align: left;color: #6c757d!important; font-size: medium;'>
    //       <h3 style='font-size: 1.75rem;'>Prezado Cliente</h3>
    //       <br />
    //       <p style="text-align: justify;">
    //         Nosso site esta passando por atualização na data de hoje
    //         Retornaremos na terça feira dia 28/02/2023.<br>
    //         Agradecemos sua compreensão.
    //       </p>
    //         <br />
    //         <br />
    //         <br />
    //         <center>Equipe OnlyPay</center>
    //     </div>
    //   `,
    //   type: 'warning',
    //   showConfirmButton: false,
    //   confirmButtonText: 'Voltar',
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false
    // })
  }
}
</script>

<template>
  <div id="app">
    <router-view />
    <transition name="message">
      <div
        v-if="$store.state.message.text"
        v-bind:class="`message message--${$store.state.message.type}`"
        v-html="$store.state.message.text"/>
    </transition>
    <div v-if="$store.state.pageLoading" class="page-loading">
      <div class="page-loading__content">
        <div class="page-loading__content__ring"><div/><div/><div/><div/></div>
        <svg class="page-loading__content__img" viewBox="0 0 634.905 340.881">
          <path
            fill="#006EB7"
            d="M169.954,0c97.883,0,170.926,73.046,170.926,170.928c0,96.907-73.044,169.952-170.926,169.952
            C73.048,340.881,0,267.835,0,170.928C0,73.046,73.048,0,169.954,0z M170.442,263.939c53.565,0,
            90.089-40.418,90.089-93.011 c0-53.569-36.524-93.499-90.089-93.499c-52.107,0-90.091,39.93-90.091,
            93.499C80.351,223.521,118.335,263.939,170.442,263.939z"/>
          <path
            fill="#006EB7"
            d="M512.676,4.87H429.89v71.098h69.638c28.73,0,50.645,18.993,50.645,51.131 c0,30.195-21.915,
            50.16-50.645,50.16H429.89v71.098h82.785c65.74,0,122.229-45.29,122.229-120.769 C634.905,43.339,
            578.416,4.87,512.676,4.87z"/>
          <path
            fill="#00FF00"
            d="M369.961,5.114h59.93v331.141h-81.833L348.043,98.14l-39.877,21.087l-30.683-61.855L369.961,5.114z"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import './assets/css/main.css';
@import './assets/css/font.css';

select[disabled], input[disabled]{
  opacity: 0.6;
  cursor: default;
}
#app {
  height: 100%;
  .message {
    color: var(--color-white);
    padding: 1rem;
    position: fixed 0 0 auto 0;
    text-align: center;
    z-index: 1000;
    &--info {
      background-color: var(--color-green);
    }
    &--error {
      background-color: var(--color-red);
    }
    &-enter, &-leave-to {
      opacity: 0;
      transform: translateY(-1rem);
    }
    &-enter-to, &-leave {
      opacity: 1;
      transform: translateY(0%);
    }
    &-enter-active, &-leave-active {
      transition: all 750ms;
    }
  }
  .page-loading {
    align-items: center;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    position: fixed 0 0 58px 0;
    z-index: 1000;
    @media (min-width: 992px) {
      bottom: 0;
      top: 110px;
    }
    &__content {
      position: relative;
      size: 4rem;
      &__ring {
        display: inline-block;
        position: relative;
        size: 100%;
        & div {
          animation: ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
          border: 2px solid var(--color-blue-light);
          border-color: var(--color-blue-light) transparent transparent transparent;
          border-radius: 50%;
          display: block;
          position: absolute;
          size: 100%;
        }
        & div:nth-child(1) {
          animation-delay: -.45s;
        }
        & div:nth-child(2) {
          animation-delay: -.3s;
        }
        & div:nth-child(3) {
          animation-delay: -.15s;
        }
        @keyframes ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      &__img {
        background: none;
        border-radius: 99em;
        fill: currentColor;
        margin: -1.5rem 0 0 -1.5rem;
        position: absolute 50% auto auto 50%;
        size: 3rem;
      }
    }
  }
}
.fade {
  &-enter-active {
    transition: all 250ms ease-in;
  }
  &-leave-active {
    transition: all 250ms ease-out;
  }
  &-enter, &-leave-to {
    opacity: 0;
  }
}
.swal2-title{
  font-size: 1.6rem!important;
}
.swal2-content{
  font-size: 18px!important;
}
.swal2-radio {
  label {
    font-size: 18px!important;
  }
  font-size: 18px!important;
}
</style>
