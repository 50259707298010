// eslint-disable-next-line import/no-cycle
import http from '../http';
// eslint-disable-next-line import/no-cycle
import store from '../store';

export function hasUserData() {
  return localStorage.getItem('user') !== null;
}

export function setUserData(userData) {
  localStorage.setItem('user', userData);
}

export function getUserData() {
  if (hasUserData()) return JSON.parse(localStorage.getItem('user'));
  return {};
}

export function removeUserData() {
  localStorage.removeItem('user');
  localStorage.removeItem('c');
}

export function cacheUserData() {
  http.get('/users/users-details').then(({ data }) => {
    store.commit('setUser', data.user);
    setUserData(JSON.stringify(data.user));
  });
}
