import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line import/no-cycle
import http from './http';
// eslint-disable-next-line import/no-cycle
import store from './store';
// eslint-disable-next-line import/no-cycle
import { hasUserData, getUserData, setUserData, removeUserData } from './helpers/user';

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  linkActiveClass: 'on',
  linkExactActiveClass: 'on-exact',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/entrar',
      name: 'signIn',
      component: () => import('./views/SignIn.vue'),
      beforeEnter: (to, from, next) => {
        if (window.$cookies.isKey('token')) {
          next({ name: 'my-cards' });
        } else {
          next();
        }
      },
      meta: { title: 'Entrar', requiresNonAuth: true },
    },
    {
      path: '/trocar-senha',
      name: 'changePassword',
      component: () => import('./views/SignIn.vue'),
      meta: { title: 'Alterar Senha', requiresNonAuth: true },
    },
    // {
    //   path: '/virtualcards/:token',
    //   name: 'virtualcards',
    //   component: () => import('./views/VirtualCards.vue'),
    //   meta: { title: 'Cartão Virtual', requiresNonAuth: true },
    // },
    {
      path: '/desbloquear-cartao',
      alias: '/desbloqueio',
      name: 'unlock',
      component: () => import('./views/Unlock.vue'),
      meta: { title: 'Desbloquear Cartão', requiresNonAuth: true },
    },
    {
      path: '/cadastro',
      name: 'signUp',
      component: () => import('./views/SignUp.vue'),
      meta: { title: 'Cadastro' },
    },
    {
      path: '/cadastro/cartao-virtual',
      name: 'VirtualCardSignUp',
      component: () => import('./views/VirtualCardSignUp.vue'),
      meta: { title: 'Cadastro Cartão Virtual' },
    },
    {
      path: '/recuperar-senha/:token',
      name: 'signInPass',
      component: () => import('./views/SignIn.vue'),
      meta: { title: 'Esqueci a senha' },
    },
    {
      path: '/termos-de-uso',
      name: 'termsOfUse',
      component: () => import('./views/Conditions.vue'),
      meta: { title: 'Termos e Condições de Uso' }
    },
    {
      path: '/termos-de-consentimento',
      name: 'terms',
      component: () => import('./views/TermsConsentimento.vue'),
      meta: { title: 'Termos de Consentimento' }
    },
    {
      path: '/politica-de-privacidade',
      name: 'conditions',
      component: () => import('./views/Terms.vue'),
      meta: { title: 'Política de Privacidade' }
    },
    {
      path: '/codigo-de-etica',
      name: 'codeOfEthics',
      component: () => import('./views/CodeOfEthics.vue'),
      meta: { title: 'Código de Ética' }
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('./views/Faq.vue'),
      meta: { title: 'FAQ Cartão OnlyPay' }
    },
    {
      path: '',
      component: () => import('./views/SignedIn.vue'),
      meta: { title: '' },
      children: [
        {
          path: '/',
          name: 'my-cards',
          component: () => import('./views/MyCards.vue'),
          meta: { title: 'Meus Cartões', requiresAuth: true },
        },
        {
          path: '/minhas-despesas',
          name: 'expenses',
          component: () => import('./views/Expenses.vue'),
          meta: { title: 'Minhas Despesas', requiresAuth: true },
        },
        {
          path: '/recarga-celular',
          name: 'cel-recharge',
          component: () => import('./views/CelRecharge.vue'),
          meta: { title: 'Recarga de Celular', requiresAuth: true },
        },
        {
          path: '/extrato-pagamento',
          name: 'statementPayment',
          component: () => import('./views/PaymentStatement.vue'),
          meta: { title: 'Boleto', requiresAuth: true }
        },
        {
          path: '/pagamento-contas',
          name: 'payment',
          component: () => import('./views/Payment.vue'),
          meta: { title: 'Pagamentos', requiresAuth: true },
        },
        {
          path: '/minhas-despesas/detalhes',
          name: 'expenses-details',
          component: () => import('./views/ExpensesDetails.vue'),
          meta: { title: 'Despesas - Detalhes', requiresAuth: true },
        },
        {
          path: '/extrato-pagamento',
          name: 'extract',
          component: () => import('./views/Extract.vue'),
          meta: { title: 'Extrato', requiresAuth: true },
        },
        // {
        //   path: '/transferir',
        //   name: 'transfer',
        //   component: () => import('./views/Transfer.vue'),
        //   meta: { title: 'Transferir', requiresAuth: true },
        // },
        {
          path: '/bank-transfer',
          name: 'banktransfer',
          component: () => import('./views/BankTransfer.vue'),
          meta: { title: 'Transferência TED', requiresAuth: true },
        },
        // {
        //   path: '/transferir/de/:senderCardId',
        //   name: 'transferTo',
        //   component: () => import('./views/TransferTo.vue'),
        //   meta: { title: 'Transf. entre cartões', requiresAuth: true },
        // },
        // {
        //   path: '/transferir/de/:senderCardId/para/:receiverCardNumber/:amount',
        //   name: 'transferConfirm',
        //   component: () => import('./views/TransferConfirm.vue'),
        //   meta: { title: 'Transf. entre cartões', requiresAuth: true },
        // },
        // {
        //   path: '/transferir/confirmacao',
        //   name: 'transferConfirm',
        //   component: () => import(/* webpackChunkName: "transferConfirm" */ './views/TransferConfirm.vue'),
        //   meta: { title: 'Transferir' },
        // },
        {
          path: '/meus-dados',
          name: 'account',
          component: () => import('./views/Account.vue'),
          meta: { title: 'Dados Pessoais', requiresAuth: true },
        },
      ],
    },
    {
      path: '**',
      redirect: '/'
    }
  ],
});

router.beforeEach((to, from, next) => {
  document.title = `OnlyPay - ${to.meta.title || ''}`;
  if (to.meta.requiresAuth) {
    if (!window.$cookies.isKey('token') && to.name !== 'signIn') {
      removeUserData();
      next({
        path: '/entrar',
        query: { redirect: to.fullPath },
      });
    } else if (!store.state.user.idConta) {
      if (hasUserData()) {
        store.commit('setUser', getUserData());
      } else {
        http.get((`${process.env.VUE_APP_HUB_BASE_URL2}/users/users-details`)).then(({ data }) => {
          store.commit('setUser', data.user);
          setUserData(JSON.stringify(data.user));
          if (to === 'backoffice' && data.user.devAccount) next({ path: '/backoffice' });
          else next();
        });
      }
      next();
    } else next();
  } else if (to.meta.requiresNonAuth && window.$cookies.isKey('token')) {
    // if (to.name === 'unlock') {
    if (/unlock|virtualcards/.test(to.name)) {
      window.$cookies.remove('token');
      removeUserData();
      next();
    } else next({ name: 'my-cards' });
  } else next();
});


export default router;
